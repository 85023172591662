import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCreateQueryMutation, useSendOtpMutation, useVerifyOtpMutation, useCheckEmailVerificationStatusQuery, OtpActions } from '../../gql/graphql';
import styles from './Enquiry.module.scss';
import Cookies from 'js-cookie';
import encryptWithPublicKey from './../../Utils/encryption';
import useQueryFormData from './../../hooks/useQueryForm';

const Enquiry = () => {
  const initialQueryData = { name: '', email: '', phoneNo: '', description: '', otp: '' };
  const [formData, errors, handleInputChange, clearForm, isFormValid] = useQueryFormData(initialQueryData);
  const [isOpen, setIsOpen] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [createQuery, { loading: creating, error }] = useCreateQueryMutation();
  const [sendOtp, { loading: sendingOtp }] = useSendOtpMutation();
  const [verifyOtp, { loading: verifyingOtp }] = useVerifyOtpMutation();
  const { data: verificationData, loading: checkingVerification } = useCheckEmailVerificationStatusQuery({
    variables: { email: formData.email, method: OtpActions.CREATE_QUERY },
    skip: !formData.email
  });

  const handleActionButtonClick = async () => {
    if (!isFormValid()) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (!verificationData?.checkEmailVerificationStatus?.success && !otpSent) {
      const sendResult = await sendOtp({ variables: { email: formData.email, method: OtpActions.CREATE_QUERY } });
      if (sendResult.data?.sendOtp?.success) {
        toast.success('OTP sent successfully.');
        setOtpSent(true);
      } else {
        toast.error(sendResult.data?.sendOtp?.message || 'Failed to send OTP. Please try again.');
      }
    } else if (otpSent) {
      const verifyResult = await verifyOtp({ variables: { email: formData.email, method: OtpActions.CREATE_QUERY, inputOtp: formData.otp } });
      if (verifyResult.data?.verifyOtp?.success) {
        setOtpSent(false);
        handleSubmitQuery();
      } else {
        toast.error(verifyResult.data?.verifyOtp?.message || 'OTP verification failed. Please check the OTP and try again.');
      }
    } else {
      handleSubmitQuery();
    }
  };

  const handleSubmitQuery = async () => {
    const publicKey = Cookies.get('_SPK');
    console.log(publicKey);
    if (!publicKey) {
      toast.error('Public key not found. Cannot encrypt data.');
      return;
    }
    try {
      const encryptedName = await encryptWithPublicKey(publicKey, formData.name);
      const encryptedEmail = await encryptWithPublicKey(publicKey, formData.email);
      const encryptedPhoneNo = await encryptWithPublicKey(publicKey, formData.phoneNo);
      const encryptedDescription = await encryptWithPublicKey(publicKey, `${formData.description}. Contact Phone: ${formData.phoneNo}`);
      const response = await createQuery({
        errorPolicy: 'all',
        variables: {
          input: {
            name: encryptedName,
            email: encryptedEmail,
            phoneNo: encryptedPhoneNo,
            description: encryptedDescription,
            subject: "Enquiry"
          }
        }
      });

      if (response.data && !error) {
        toast.success('Successfully submitted the enquiry.');
        clearForm();
      } else {
        toast.error(`Failed to submit the enquiry: ${error?.message}`);
      }
    } catch (err) {
      console.error('Error submitting encrypted enquiry:', err);
      toast.error('Error: Submission failed. Please check the console for more details.');
    }
  };

  const toggleEnquiry = () => setIsOpen(!isOpen);

  return (
    <section className={`${styles.enquirySection} ${isOpen ? styles.open : ""}`}>
      <div className={`${styles.toggle} ${isOpen ? styles["no-blink"] : ""}`} onClick={toggleEnquiry}>
        <p>E</p>
        <p>N</p>
        <p>Q</p>
        <p>U</p>
        <p>I</p>
        <p>R</p>
        <p>Y</p>
      </div>
      <div className={styles.scroll}>
        <h3>
          <i className="fa-solid fa-headset"></i> Schedule A Call Back
        </h3>
        <p>Our Experts are ready to assist you. Let's Connect with Us By submitting your enquiry.</p>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className={styles.formGroup}>
            <input
              type="text"
              name="name"
              className={styles.field}
              placeholder="Enter Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            {errors.name && <p className={styles.error}>{errors.name}</p>}
          </div>
          <div className={styles.formGroup}>
            <input
              type="email"
              name="email"
              className={styles.field}
              placeholder="Email Address"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {errors.email && <p className={styles.error}>{errors.email}</p>}
          </div>
          <div className={styles.formGroup}>
            <div className={styles.phoneContainer}>
              <div className={styles.country}>
                <div className={styles.countryFlag}>🇮🇳</div>
                <div className={styles.countryCode}>+91</div>
              </div>
              <input
                type="tel"
                name="phoneNo"
                className={styles.field}
                placeholder="Mobile"
                value={formData.phoneNo}
                onChange={handleInputChange}
                required
              />
            </div>
            {errors.phoneNo && <p className={styles.error}>{errors.phoneNo}</p>}
          </div>
          <div className={styles.formGroup}>
            <textarea
              name="description"
              className={`${styles.field} ${styles.textarea}`}
              placeholder="Write some description here..."
              value={formData.description}
              onChange={handleInputChange}
              required
            />
            {errors.description && <p className={styles.error}>{errors.description}</p>}
          </div>
          {otpSent && (
            <div className={styles.formGroup}>
              <input
                type="text"
                name="otp"
                className={styles.field}
                placeholder="Enter OTP"
                value={formData.otp}
                onChange={handleInputChange}
                required
              />
              {errors.otp && <p className={styles.error}>{errors.otp}</p>}
            </div>
          )}
          <button
            onClick={handleActionButtonClick}
            disabled={creating || sendingOtp || verifyingOtp}
            className={styles.submitButton}
          >
            {creating || sendingOtp || verifyingOtp ? (
              <div className={styles.loader}></div> // Display loader when processing
            ) : (
              "Submit Enquiry"
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Enquiry;
