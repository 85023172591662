import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faBoxes,
  faGlobe,
  faRecycle,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./Customers.module.scss";

const Customers = () => {
  return (
    <section id="customers" className={styles.customersSection}>
      <div className={styles.container}>
        <div className={styles.sectionHeading}>
          <h2>Who Needs Our Services?</h2>
          <p>
            Compliance is essential in today's global marketplace. Ducat's
            services cater to a diverse range of businesses and organizations.
          </p>
        </div>
        <div className={styles.list}>
          <ul>
            <li>
              <div className={styles.customer}>
                <div>
                  <FontAwesomeIcon icon={faIndustry} className={styles.icon} />
                </div>
                <div>
                  <h3>Manufacturers</h3>
                  <p>
                    Starting a manufacturing unit involves proper business
                    planning and multiple other parameters. Ducat offers
                    valuable support to plan and manage these mandatory
                    parameters for business success.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className={styles.customer}>
                <div>
                  <FontAwesomeIcon icon={faBoxes} className={styles.icon} />
                </div>
                <div>
                  <h3>Producer</h3>
                  <p>
                    Ducat helps producers adopt sustainable practices through
                    guidance, training, and incentives. We offer programs to
                    enhance knowledge and skills in waste reduction, efficient
                    plant operations, and compliance with environmental
                    standards. Additionally, we assist producers in complying
                    with regulations governing waste disposal, emissions, and
                    product compliance
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className={styles.customer}>
                <div>
                  <FontAwesomeIcon icon={faBoxes} className={styles.icon} />
                </div>
                <div>
                  <h3>Importers</h3>
                  <p>
                    Ducat plays a crucial role in supporting importers in their
                    environmental projects and plant setups. By ensuring
                    compliance with environmental regulations, Ducat helps align
                    imports with environmentally responsible practices.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className={styles.customer}>
                <div>
                  <FontAwesomeIcon icon={faGlobe} className={styles.icon} />
                </div>
                <div>
                  <h3>Exporters</h3>
                  <p>
                    In the realm of plant setup and environmental projects,
                    Ducat encourages exporters to adhere to environmental
                    compliance standards, contributing to sustainable
                    development and mitigating potential environmental risks.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className={styles.customer}>
                <div>
                  <FontAwesomeIcon icon={faRecycle} className={styles.icon} />
                </div>
                <div>
                  <h3>Recycling Businesses</h3>
                  <p>
                    To start a recycling business, various permits and
                    authorizations are required. Ducat can help plan and manage
                    these mandatory parameters for business success.
                  </p>
                </div>
              </div>
            </li>
            {/* <li>
              <div className={styles.customer}>
                <div>
                  <FontAwesomeIcon icon={faIndustry} className={styles.icon} />
                </div>
                <div>
                  <h3>Others</h3>
                  <p>
                    Explore business possibilities beyond manufacturing or
                    recycling with the right licenses. Ducat offers valuable
                    support to start any type of business in India and
                    worldwide.
                  </p>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Customers;
