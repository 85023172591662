import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faBuilding, faLeaf } from '@fortawesome/free-solid-svg-icons';
import styles from './Services.module.scss';
import ModalComponent from './ModalComponent'; // Import the Modal component

interface SubService {
  name: string;
  url: string;
}

const Services: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const openModal = (url: string) => {
    setPdfUrl(url);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setPdfUrl('');
  };

  const subServices: SubService[] = [
    { name: 'Carbon Neutral', url: require("../../Utils/pdfs/ducat.pdf") },
    { name: 'ESG Solutions', url: '' },
    { name: 'FSC Content', url: '' },
    { name: 'PEFC', url: '' },
    { name: 'Supply Chain Auditing', url: '' },
  ];

  return (
    <section id="services" className={styles.servicesSection}>
      <div className={styles.container}>
        <div className={styles.sectionHeading}>
          <h2>Our Services</h2>
          <p>Ducat offers a range of services to meet the compliance needs of businesses in various industries.</p>
        </div>
        <div className={styles.servicesRow}>
          <div className={styles.serviceCard}>
            <div className={styles.cardInner}>
              <div className={styles.cardFront}>
                <FontAwesomeIcon icon={faChartBar} className={styles.icon} />
                <h3>Business Planning Services</h3>
                <p>Detailed Feasibility Report, Entity Incorporation, Trademark Registration, Land Acquisition, Audit and Land Document Scrutiny, Technological Acquisition, and more.</p>
              </div>
              <div className={styles.cardBack}>

              </div>
            </div>
          </div>
          <div className={styles.serviceCard}>
            <div className={styles.cardInner}>
              <div className={styles.cardFront}>
                <FontAwesomeIcon icon={faBuilding} className={styles.icon} />
                <h3>Compliances Required By Local Authorities</h3>
                <p>Construction Plan Approval, Municipality Sewer Connection, Electricity Connection, Water Connection, CGWA, Fire NOC, Trade License, Factory License, and more.</p>
              </div>
              <div className={styles.cardBack}>

              </div>
            </div>
          </div>
          <div className={styles.serviceCard}>
            <div className={styles.cardInner}>
              <div className={styles.cardFront}>
                <FontAwesomeIcon icon={faLeaf} className={styles.icon} />
                <h3>Environmental Compliance</h3>
                <p>CTE, CTO, Environmental Impact Assessment, Environmental Clearance, EPR, STP/ETP/ZLD Setup solutions, Rain Water Harvesting System Solutions, and more.</p>
              </div>
              <div className={styles.cardBack}>
                <ul>
                  {subServices.map((service, index) => (
                    <li key={index} onClick={() => openModal(service.url)}>{service.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        pdfUrl={pdfUrl}
      />
    </section>
  );
};

export default Services;
