import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export enum ActiveStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  INACTIVE = 'INACTIVE'
}

export enum ApiModules {
  BLOGS = 'BLOGS',
  CLIENTS = 'CLIENTS',
  PORTFOLIOS = 'PORTFOLIOS',
  QUERIES = 'QUERIES',
  SERVICES = 'SERVICES',
  USERS = 'USERS'
}

export type Category = {
  _id?: Maybe<Scalars['ID']['output']>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type Client = {
  __typename?: 'Client';
  clientApiPool?: Maybe<Array<Maybe<ApiModules>>>;
  clientAuthToken?: Maybe<Scalars['String']['output']>;
  clientDbUrl?: Maybe<Scalars['String']['output']>;
  clientFeedback?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactNo?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  projectDNS?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  projectIconUrl?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  smtpHost?: Maybe<Scalars['String']['output']>;
  smtpPass?: Maybe<Scalars['String']['output']>;
  smtpPort?: Maybe<Scalars['Int']['output']>;
  smtpUser?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ActiveStatus>;
  storage?: Maybe<Scalars['Boolean']['output']>;
  updateAt?: Maybe<Scalars['Date']['output']>;
};

export type ClientDetailsInput = {
  clientApiPool?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientDbUrl?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactNo?: InputMaybe<Scalars['String']['input']>;
  projectDNS?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectIcon?: InputMaybe<Scalars['Upload']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  smtpHost?: InputMaybe<Scalars['String']['input']>;
  smtpPass?: InputMaybe<Scalars['String']['input']>;
  smtpPort?: InputMaybe<Scalars['Int']['input']>;
  smtpUser?: InputMaybe<Scalars['String']['input']>;
  storage?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CommentStatus {
  DELETED = 'DELETED',
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE'
}

export type CredentialsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type ForgotPasswordInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type Logs = {
  __typename?: 'Logs';
  _id?: Maybe<Scalars['ID']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addActivityLog?: Maybe<Scalars['Void']['output']>;
  createPortfolio?: Maybe<ResponseType1>;
  createPortfolioCategory?: Maybe<ResponseType1>;
  createPost?: Maybe<ResponseType1>;
  createPostCategory?: Maybe<ResponseType1>;
  createPostTag?: Maybe<ResponseType1>;
  createQuery?: Maybe<ResponseType1>;
  createServiceCategory?: Maybe<ResponseType1>;
  createServiceSubCategory?: Maybe<ResponseType1>;
  createUsers?: Maybe<ResponseType1>;
  deletePortfolio?: Maybe<ResponseType3>;
  deletePortfolioCategory?: Maybe<ResponseType3>;
  deletePost?: Maybe<ResponseType3>;
  deletePostCategory?: Maybe<ResponseType3>;
  deletePostTag?: Maybe<ResponseType3>;
  deleteServiceCategory?: Maybe<ResponseType3>;
  deleteServiceSubCategory?: Maybe<ResponseType3>;
  forgotPassword?: Maybe<ResponseType3>;
  generate2FASecret?: Maybe<ResponseType3>;
  logUserViaMail?: Maybe<ResponseType1>;
  logUserViaSSO?: Maybe<ResponseType1>;
  logoutUser?: Maybe<ResponseType3>;
  registerClient?: Maybe<ResponseType1>;
  resetPassword?: Maybe<ResponseType3>;
  resetPasswordLoggedUser?: Maybe<ResponseType3>;
  sendOtp?: Maybe<ResponseType3>;
  updatePortfolio?: Maybe<ResponseType1>;
  updatePost?: Maybe<ResponseType1>;
  updatePostTag?: Maybe<ResponseType1>;
  updateQuery?: Maybe<ResponseType1>;
  updateServiceSubCategory?: Maybe<ResponseType1>;
  updateUser?: Maybe<ResponseType1>;
  verify2FA?: Maybe<ResponseType1>;
  verifyOtp?: Maybe<ResponseType3>;
  verifySession?: Maybe<ResponseType1>;
};


export type MutationCreatePortfolioArgs = {
  input: PortfolioInput;
};


export type MutationCreatePortfolioCategoryArgs = {
  input: PortfolioCategoryInput;
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


export type MutationCreatePostCategoryArgs = {
  input: PostCategoryInput;
};


export type MutationCreatePostTagArgs = {
  input: PostTagInput;
};


export type MutationCreateQueryArgs = {
  input: QueriesInput;
};


export type MutationCreateServiceCategoryArgs = {
  input: ServiceCategoryInput;
};


export type MutationCreateServiceSubCategoryArgs = {
  input: ServiceSubCategoryInput;
};


export type MutationCreateUsersArgs = {
  input: UserInput;
};


export type MutationDeletePortfolioArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeletePortfolioCategoryArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeletePostArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeletePostCategoryArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeletePostTagArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeleteServiceCategoryArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationDeleteServiceSubCategoryArgs = {
  _id: Scalars['ID']['input'];
};


export type MutationForgotPasswordArgs = {
  input?: InputMaybe<ForgotPasswordInput>;
};


export type MutationLogUserViaMailArgs = {
  input: CredentialsInput;
};


export type MutationRegisterClientArgs = {
  input: ClientDetailsInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResetPasswordLoggedUserArgs = {
  input: ResetPasswordLoggedUserInput;
};


export type MutationSendOtpArgs = {
  email: Scalars['String']['input'];
  method: OtpActions;
};


export type MutationUpdatePortfolioArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdatePostArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdatePostTagArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdateQueryArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data: QueriesStatus;
};


export type MutationUpdateServiceSubCategoryArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdateUserArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationVerify2FaArgs = {
  input?: InputMaybe<Verify2FaInput>;
};


export type MutationVerifyOtpArgs = {
  email: Scalars['String']['input'];
  inputOtp: Scalars['String']['input'];
  method: OtpActions;
};

export enum OtpActions {
  CREATE_QUERY = 'CREATE_QUERY',
  CREATE_USER = 'CREATE_USER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  LOGIN = 'LOGIN'
}

export enum Permissions {
  ALL = 'ALL',
  DELETE = 'DELETE',
  READ = 'READ',
  WRITE = 'WRITE'
}

export type Portfolio = {
  __typename?: 'Portfolio';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  featuresList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  previewImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type PortfolioCategory = Category & {
  __typename?: 'PortfolioCategory';
  _id?: Maybe<Scalars['ID']['output']>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PortfolioCategoryInput = {
  coverPictureUrl?: InputMaybe<Scalars['Upload']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PortfolioInput = {
  featuresList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewImageUrl?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Post = {
  __typename?: 'Post';
  _id?: Maybe<Scalars['ID']['output']>;
  authorId?: Maybe<Scalars['ID']['output']>;
  category?: Maybe<Scalars['ID']['output']>;
  commentsEnabled?: Maybe<Scalars['Boolean']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  publishedAt?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<PostStatus>;
  tags?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PostCategory = Category & {
  __typename?: 'PostCategory';
  _id?: Maybe<Scalars['ID']['output']>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PostCategoryInput = {
  coverPictureUrls?: InputMaybe<Scalars['Upload']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PostComments = {
  __typename?: 'PostComments';
  _id?: Maybe<Scalars['ID']['output']>;
  authorId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  postId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<CommentStatus>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export enum PostStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export type PostTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  tagName?: InputMaybe<Scalars['String']['input']>;
};

export type PostTags = {
  __typename?: 'PostTags';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  tagName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type Queries = {
  __typename?: 'Queries';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNo?: Maybe<Scalars['String']['output']>;
  status?: Maybe<QueriesStatus>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type QueriesInput = {
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNo: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export enum QueriesStatus {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED'
}

export type Query = {
  __typename?: 'Query';
  checkEmailVerificationStatus?: Maybe<ResponseType3>;
  getClients?: Maybe<ResponseType2>;
  getClientsDetails?: Maybe<ResponseType1>;
  getPortfolioCategories?: Maybe<ResponseType2>;
  getPortfolios?: Maybe<ResponseType2>;
  getPostCategories?: Maybe<ResponseType2>;
  getPostTags?: Maybe<ResponseType2>;
  getPosts?: Maybe<ResponseType2>;
  getQueries?: Maybe<ResponseType2>;
  getServiceCategories?: Maybe<ResponseType2>;
  getServiceSubCategories?: Maybe<ResponseType2>;
  getUsers?: Maybe<ResponseType2>;
};


export type QueryCheckEmailVerificationStatusArgs = {
  email: Scalars['String']['input'];
  method: OtpActions;
};


export type QueryGetClientsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetClientsDetailsArgs = {
  feedbacks: Scalars['Boolean']['input'];
  icons: Scalars['Boolean']['input'];
};


export type QueryGetPortfolioCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPortfoliosArgs = {
  Category?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPostCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPostTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPostsArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetQueriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetServiceCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetServiceSubCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  roleName?: InputMaybe<RoleName>;
};

export type ResetPasswordInput = {
  otp?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type ResetPasswordLoggedUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type ResponseData = Client | Portfolio | PortfolioCategory | Post | PostCategory | PostComments | PostTags | Queries | ServiceCategory | ServiceSubCategory | User;

export enum RoleName {
  ADMIN = 'ADMIN',
  EXTERNAL_USER = 'EXTERNAL_USER',
  MANAGER = 'MANAGER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  TEAM_LEAD = 'TEAM_LEAD',
  TEAM_MEMBER = 'TEAM_MEMBER'
}

export type Roles = {
  __typename?: 'Roles';
  _id?: Maybe<Scalars['ID']['output']>;
  permissions?: Maybe<Array<Maybe<Permissions>>>;
  roleName?: Maybe<RoleName>;
};

export type RolesInput = {
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roleName?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceCategory = Category & {
  __typename?: 'ServiceCategory';
  _id?: Maybe<Scalars['ID']['output']>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ServiceCategoryInput = {
  coverPictureUrl?: InputMaybe<Scalars['Upload']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceSubCategory = {
  __typename?: 'ServiceSubCategory';
  _id?: Maybe<Scalars['ID']['output']>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  desriptionList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ServiceSubCategoryInput = {
  coverPictureUrl?: InputMaybe<Scalars['String']['input']>;
  desriptionList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ID']['output']>;
  activityLogs?: Maybe<Array<Maybe<Logs>>>;
  coverPictureUrl?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  googleId?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Logs>;
  lastName?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<Roles>>>;
  status?: Maybe<ActiveStatus>;
  twoFactorEnabled?: Maybe<Scalars['Boolean']['output']>;
  twoFactorSecret?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Date']['output']>;
};

export type UserInput = {
  coverPicture?: InputMaybe<Scalars['Upload']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['Upload']['input']>;
  roles?: InputMaybe<Array<RolesInput>>;
};

export type Verify2FaInput = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePostInput = {
  authorId?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<Scalars['ID']['input']>;
  commentsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ResponseType1 = {
  __typename?: 'responseType1';
  data?: Maybe<ResponseData>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ResponseType2 = {
  __typename?: 'responseType2';
  data?: Maybe<ResponseData>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  totalCounts?: Maybe<Scalars['Int']['output']>;
};

export type ResponseType3 = {
  __typename?: 'responseType3';
  data?: Maybe<Scalars['JSON']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SendOtpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  method: OtpActions;
}>;


export type SendOtpMutation = { __typename?: 'Mutation', sendOtp?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: any | null } | null };

export type VerifyOtpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  method: OtpActions;
  inputOtp: Scalars['String']['input'];
}>;


export type VerifyOtpMutation = { __typename?: 'Mutation', verifyOtp?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: any | null } | null };

export type CheckEmailVerificationStatusQueryVariables = Exact<{
  email: Scalars['String']['input'];
  method: OtpActions;
}>;


export type CheckEmailVerificationStatusQuery = { __typename?: 'Query', checkEmailVerificationStatus?: { __typename?: 'responseType3', success: boolean, message?: string | null, data?: any | null } | null };

export type QueryFieldsFragment = { __typename?: 'Queries', _id?: string | null, name?: string | null, email?: string | null, subject?: string | null, phoneNo?: string | null, description?: string | null, status?: QueriesStatus | null, createdAt?: any | null, updatedAt?: any | null };

export type CreateQueryMutationVariables = Exact<{
  input: QueriesInput;
}>;


export type CreateQueryMutation = { __typename?: 'Mutation', createQuery?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: { __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries', _id?: string | null, name?: string | null, email?: string | null, subject?: string | null, phoneNo?: string | null, description?: string | null, status?: QueriesStatus | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null } | null };

export type UpdateQueryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: QueriesStatus;
}>;


export type UpdateQueryMutation = { __typename?: 'Mutation', updateQuery?: { __typename?: 'responseType1', success: boolean, message?: string | null, data?: { __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries', _id?: string | null, name?: string | null, email?: string | null, subject?: string | null, phoneNo?: string | null, description?: string | null, status?: QueriesStatus | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null } | null };

export type GetQueriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetQueriesQuery = { __typename?: 'Query', getQueries?: { __typename?: 'responseType2', success: boolean, message?: string | null, totalCounts?: number | null, data?: { __typename?: 'Client' } | { __typename?: 'Portfolio' } | { __typename?: 'PortfolioCategory' } | { __typename?: 'Post' } | { __typename?: 'PostCategory' } | { __typename?: 'PostComments' } | { __typename?: 'PostTags' } | { __typename?: 'Queries', _id?: string | null, name?: string | null, email?: string | null, subject?: string | null, phoneNo?: string | null, description?: string | null, status?: QueriesStatus | null, createdAt?: any | null, updatedAt?: any | null } | { __typename?: 'ServiceCategory' } | { __typename?: 'ServiceSubCategory' } | { __typename?: 'User' } | null } | null };

export const QueryFieldsFragmentDoc = gql`
    fragment QueryFields on Queries {
  _id
  name
  email
  subject
  phoneNo
  description
  status
  createdAt
  updatedAt
}
    `;
export const SendOtpDocument = gql`
    mutation SendOtp($email: String!, $method: OtpActions!) {
  sendOtp(email: $email, method: $method) {
    success
    message
    data
  }
}
    `;
export type SendOtpMutationFn = Apollo.MutationFunction<SendOtpMutation, SendOtpMutationVariables>;

/**
 * __useSendOtpMutation__
 *
 * To run a mutation, you first call `useSendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOtpMutation, { data, loading, error }] = useSendOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useSendOtpMutation(baseOptions?: Apollo.MutationHookOptions<SendOtpMutation, SendOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendOtpMutation, SendOtpMutationVariables>(SendOtpDocument, options);
      }
export type SendOtpMutationHookResult = ReturnType<typeof useSendOtpMutation>;
export type SendOtpMutationResult = Apollo.MutationResult<SendOtpMutation>;
export type SendOtpMutationOptions = Apollo.BaseMutationOptions<SendOtpMutation, SendOtpMutationVariables>;
export const VerifyOtpDocument = gql`
    mutation VerifyOtp($email: String!, $method: OtpActions!, $inputOtp: String!) {
  verifyOtp(email: $email, method: $method, inputOtp: $inputOtp) {
    success
    message
    data
  }
}
    `;
export type VerifyOtpMutationFn = Apollo.MutationFunction<VerifyOtpMutation, VerifyOtpMutationVariables>;

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      method: // value for 'method'
 *      inputOtp: // value for 'inputOtp'
 *   },
 * });
 */
export function useVerifyOtpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOtpMutation, VerifyOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(VerifyOtpDocument, options);
      }
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>;
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>;
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const CheckEmailVerificationStatusDocument = gql`
    query CheckEmailVerificationStatus($email: String!, $method: OtpActions!) {
  checkEmailVerificationStatus(email: $email, method: $method) {
    success
    message
    data
  }
}
    `;

/**
 * __useCheckEmailVerificationStatusQuery__
 *
 * To run a query within a React component, call `useCheckEmailVerificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailVerificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailVerificationStatusQuery({
 *   variables: {
 *      email: // value for 'email'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useCheckEmailVerificationStatusQuery(baseOptions: Apollo.QueryHookOptions<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables> & ({ variables: CheckEmailVerificationStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>(CheckEmailVerificationStatusDocument, options);
      }
export function useCheckEmailVerificationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>(CheckEmailVerificationStatusDocument, options);
        }
export function useCheckEmailVerificationStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>(CheckEmailVerificationStatusDocument, options);
        }
export type CheckEmailVerificationStatusQueryHookResult = ReturnType<typeof useCheckEmailVerificationStatusQuery>;
export type CheckEmailVerificationStatusLazyQueryHookResult = ReturnType<typeof useCheckEmailVerificationStatusLazyQuery>;
export type CheckEmailVerificationStatusSuspenseQueryHookResult = ReturnType<typeof useCheckEmailVerificationStatusSuspenseQuery>;
export type CheckEmailVerificationStatusQueryResult = Apollo.QueryResult<CheckEmailVerificationStatusQuery, CheckEmailVerificationStatusQueryVariables>;
export const CreateQueryDocument = gql`
    mutation CreateQuery($input: QueriesInput!) {
  createQuery(input: $input) {
    success
    message
    data {
      ...QueryFields
    }
  }
}
    ${QueryFieldsFragmentDoc}`;
export type CreateQueryMutationFn = Apollo.MutationFunction<CreateQueryMutation, CreateQueryMutationVariables>;

/**
 * __useCreateQueryMutation__
 *
 * To run a mutation, you first call `useCreateQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQueryMutation, { data, loading, error }] = useCreateQueryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQueryMutation(baseOptions?: Apollo.MutationHookOptions<CreateQueryMutation, CreateQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQueryMutation, CreateQueryMutationVariables>(CreateQueryDocument, options);
      }
export type CreateQueryMutationHookResult = ReturnType<typeof useCreateQueryMutation>;
export type CreateQueryMutationResult = Apollo.MutationResult<CreateQueryMutation>;
export type CreateQueryMutationOptions = Apollo.BaseMutationOptions<CreateQueryMutation, CreateQueryMutationVariables>;
export const UpdateQueryDocument = gql`
    mutation UpdateQuery($id: ID!, $data: QueriesStatus!) {
  updateQuery(data: $data, _id: $id) {
    success
    message
    data {
      ...QueryFields
    }
  }
}
    ${QueryFieldsFragmentDoc}`;
export type UpdateQueryMutationFn = Apollo.MutationFunction<UpdateQueryMutation, UpdateQueryMutationVariables>;

/**
 * __useUpdateQueryMutation__
 *
 * To run a mutation, you first call `useUpdateQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQueryMutation, { data, loading, error }] = useUpdateQueryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQueryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQueryMutation, UpdateQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQueryMutation, UpdateQueryMutationVariables>(UpdateQueryDocument, options);
      }
export type UpdateQueryMutationHookResult = ReturnType<typeof useUpdateQueryMutation>;
export type UpdateQueryMutationResult = Apollo.MutationResult<UpdateQueryMutation>;
export type UpdateQueryMutationOptions = Apollo.BaseMutationOptions<UpdateQueryMutation, UpdateQueryMutationVariables>;
export const GetQueriesDocument = gql`
    query GetQueries($limit: Int, $offset: Int) {
  getQueries(limit: $limit, offset: $offset) {
    success
    message
    totalCounts
    data {
      ...QueryFields
    }
  }
}
    ${QueryFieldsFragmentDoc}`;

/**
 * __useGetQueriesQuery__
 *
 * To run a query within a React component, call `useGetQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQueriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetQueriesQuery(baseOptions?: Apollo.QueryHookOptions<GetQueriesQuery, GetQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQueriesQuery, GetQueriesQueryVariables>(GetQueriesDocument, options);
      }
export function useGetQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQueriesQuery, GetQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQueriesQuery, GetQueriesQueryVariables>(GetQueriesDocument, options);
        }
export function useGetQueriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetQueriesQuery, GetQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQueriesQuery, GetQueriesQueryVariables>(GetQueriesDocument, options);
        }
export type GetQueriesQueryHookResult = ReturnType<typeof useGetQueriesQuery>;
export type GetQueriesLazyQueryHookResult = ReturnType<typeof useGetQueriesLazyQuery>;
export type GetQueriesSuspenseQueryHookResult = ReturnType<typeof useGetQueriesSuspenseQuery>;
export type GetQueriesQueryResult = Apollo.QueryResult<GetQueriesQuery, GetQueriesQueryVariables>;