import React from "react";
import styles from "./TopHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const TopHeader = () => {
  return (
    <section className={styles.TopHeader}>
      <div className={styles.content}>
        <div className={styles.detail}>
          <FontAwesomeIcon icon={faPhone} className={styles.icon} />
          <div>
            <p>+91-72920 54196</p>
            <p>+91-72920 54196</p>
          </div>
        </div>
        <div className={styles.detail}>
          <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
          <p>info@ducatgroup.com</p>
        </div>
        <div className={styles.detail}>
          <FontAwesomeIcon icon={faLinkedinIn} className={styles.icon} />
          <p>
            <a href="https://www.linkedin.com/company/ducat-consultancy-pvt-ltd/">
              Ducat Consultancy Pvt Ltd
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default TopHeader;
