import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faBullseye } from "@fortawesome/free-solid-svg-icons";
import  styles from "./About.module.scss";

const About = () => {
  return (
    <section id="about" className={styles.aboutSection}>
      <div className={styles.container}>
        <div className={styles.sectionHeading}>
          <h2>About Our Company</h2>
          <p >
            Ducat is a trailblazer in the fields of finance, pollution control,
            and regulatory compliance. We have established ourselves as
            pioneers, leading the way in the industry and inspiring others to do
            the same.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.aboutContent}>
            <div>
              <FontAwesomeIcon icon={faBriefcase} className={styles.icon} />
            </div>
            <div className={styles.content}>
              <h3>Our Expertise</h3>
              <p>
                With our deep understanding of the latest regulatory
                developments, we proactively meet the needs of businesses,
                public-sector organizations, non-profits, and startups
                worldwide. Our unwavering dedication to transforming the
                advisory industry has made us a trusted partner for businesses
                seeking assistance in various areas.
              </p>
              <p>
                What sets us apart is our collaborative team of experienced
                professionals, including Chartered Accountants, Company
                Secretaries, Lawyers, and Engineers, who cater to the compliance
                needs of businesses in various industries.
              </p>
            </div>
          </div>
          <div className={styles.aboutContent}>
            <div>
              <FontAwesomeIcon icon={faBullseye} className={styles.icon} />
            </div>
            <div className={styles.content}>
              <h3>Our Mission</h3>
              <p>
                At Ducat, we are committed to upholding legal and environmental
                standards while providing financial consulting services. We
                believe in helping businesses maximize their savings potential
                through our proven expertise in meeting compliance requirements
                on time.
              </p>
              <p>
                Our mission is to empower businesses to thrive in a competitive
                environment by simplifying complex regulatory landscapes and
                delivering innovative solutions that drive long-term success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
