import { useState } from 'react';

interface QueryFormData {
  [key: string]: string;
  name: string;
  email: string;
  phoneNo: string;
  description: string;
  otp: string;
}

interface FormErrors {
  [key: string]: string;
}

type UseQueryFormDataReturnType = [
  QueryFormData,
  FormErrors,
  (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  () => void,
  () => boolean
];

const useQueryFormData = (initialValues: QueryFormData): UseQueryFormDataReturnType => {
  const [formData, setFormData] = useState<QueryFormData>(initialValues);
  const [errors, setErrors] = useState<FormErrors>({});

  const validate = (name: string, value: string): string => {
    switch (name) {
      case 'name':
        if (!value) return '';
        break;
      case 'email':
        if (!value) return '';
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) return 'Email is not valid';
        break;
      case 'phoneNo':
        if (!value) return '';
        const phoneRegex = /^\d{10}$/; // Adjust the regex as needed
        if (!phoneRegex.test(value)) return 'Phone number is not valid';
        break;
      case 'description':
        if (!value) return '';
        break;
      case 'otp':
        if (!value) return '';
        const otpRegex = /^\d{6}$/; // Assuming OTP is a 6-digit number
        if (!otpRegex.test(value)) return 'OTP is not valid';
        break;
      default:
        break;
    }
    return '';
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    console.log(`Input name: ${name}, Input value: ${value}`); // Logging to see what's received

    setFormData(prev => {
      console.log(`Previous state:`, prev); // Log previous state
      const newState = { ...prev, [name]: value };
      console.log(`New state:`, newState); // Log new state
      return newState;
    });

    const error = validate(name, value);
    setErrors(prev => ({ ...prev, [name]: error }));
  };

  const clearForm = () => {
    setFormData({ name: '', email: '', phoneNo: '', description: '', otp: '' });
    setErrors({});
  };

  const isFormValid = (): boolean => {
    const validationErrors: FormErrors = {};
    Object.keys(formData).forEach(key => {
      if(formData.otp==""){
        return;
      }
      const error = validate(key, formData[key]);
      if (error) validationErrors[key] = error;
    });
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  return [formData, errors, handleInputChange, clearForm, isFormValid];
};

export default useQueryFormData;
