import React from 'react';
import Modal from 'react-modal';

const ModalComponent: React.FC<ModalComponentProps> = ({ isOpen, onRequestClose, pdfUrl }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="PDF Viewer"
            style={{
                content: {
                    top: '55%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    height: '80%',
                    padding: '0',
                    border: 'none',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    zIndex: 1002,
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    zIndex: 1001,
                },
            }}
        >
            <iframe
                src={pdfUrl}
                title="PDF Viewer"
                style={{ width: '100%', height: '100%', border: 'none' }}
            />
        </Modal>
    );
};

export default ModalComponent;
