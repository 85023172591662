import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTools,
  faThList,
  faUserFriends,
  faClipboardCheck,
  faIndustry,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./Benefits.module.scss";

const Benefits = () => {
  return (
    <section id="benefits" className={styles.benefitsSection}>
      <div className={styles.container}>
        <div className={styles.sectionHeading}>
          <h2>Why Choose Us?</h2>
          <p>
            Discover the reasons why Ducat is the ideal partner for your
            compliance and consulting needs.
          </p>
        </div>
        <div className={styles.cardRow}>
          <div className={styles.card}>
            <FontAwesomeIcon icon={faTools} className={styles.icon} />
            <h3>Expertise</h3>
            <p>
              Benefit from our team of experienced professionals who are experts
              in regulatory compliance and consulting.
            </p>
          </div>
          <div className={styles.card}>
            <FontAwesomeIcon icon={faThList} className={styles.icon} />
            <h3>Comprehensive Solutions</h3>
            <p>
              We offer a wide range of services to meet all your compliance
              requirements, ensuring a one-stop solution.
            </p>
          </div>
          <div className={styles.card}>
            <FontAwesomeIcon icon={faUserFriends} className={styles.icon} />
            <h3>Client-Centric Approach</h3>
            <p>
              Our focus is on understanding your unique needs and delivering
              tailored solutions that exceed your expectations.
            </p>
          </div>
          <div className={styles.card}>
            <FontAwesomeIcon icon={faClipboardCheck} className={styles.icon} />
            <h3>Proven Track Record</h3>
            <p>
              With years of experience and numerous successful projects, we have
              a proven track record of delivering results.
            </p>
          </div>
          <div className={styles.card}>
            <FontAwesomeIcon icon={faIndustry} className={styles.icon} />
            <h3>Industry Leader</h3>
            <p>
              Ducat is recognized as a leader in the fields of finance,
              pollution control, and regulatory compliance, setting the standard
              for excellence.
            </p>
          </div>
          <div className={styles.card}>
            <FontAwesomeIcon icon={faStar} className={styles.icon} />
            <h3>Customer Satisfaction</h3>
            <p>
              Our priority is your satisfaction. We strive to provide
              exceptional service and support to ensure your success.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
