import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styles from "./Team.module.scss";

const Team = () => {
  const team = [
    { name: "Name", image: require("../../Utils/Images/team.jpg"), desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam ornare ligula, vel auctor dolor dictum at." },
    { name: "Name", image: require("../../Utils/Images/team.jpg"), desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam ornare ligula, vel auctor dolor dictum at." },
    { name: "Name", image: require("../../Utils/Images/team.jpg"), desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam ornare ligula, vel auctor dolor dictum at." },
    { name: "Name", image: require("../../Utils/Images/team.jpg"), desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam ornare ligula, vel auctor dolor dictum at." },
    { name: "Name", image: require("../../Utils/Images/team.jpg"), desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam ornare ligula, vel auctor dolor dictum at." },
  ];

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <section className={styles.teamSection}>
      <div className={styles.container}>
        <div className={styles.sectionHeading}>
          <h2>Our Team</h2>
        </div>
        <div className={styles.slides}>
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            spaceBetween={30}
            slidesPerView={3}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            loop={true}
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {team.map((team, index) => (
              <SwiperSlide key={index} className="swiper">
                <div className={styles.slide}>
                  <div className={styles.content}>
                    <div className={styles.image}>
                      <img src={team.image} />
                      <p className={styles.name}>{team.name}</p>
                      <p className={styles.desc}>{team.desc}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
export default Team;
