import React, { useState,useEffect } from "react";
import styles from "./Header.module.scss";

const Header = () => {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isScrolled, setScrolled] = useState(false);

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`${styles.header} ${isScrolled ? styles.scrolled : ""}`}>
      <div className={styles.headerContent}>
        <div className={styles.logo}>
          <a href="#">
            <img
              src={require("../../Utils/Images/logo.png")}
              alt="Logo"
              width="60"
              height="60"
            />
          </a>
        </div>
        <div className={styles.navlink}>
          <nav className={`${styles.navigation} ${isNavVisible ? styles.show : ""}`}>
            <a href="#" className={styles.navItem} onClick={toggleNav}>
              Home
            </a>
            <a href="#about" className={styles.navItem} onClick={toggleNav}>
              About Us
            </a>
            <a href="#services" className={styles.navItem} onClick={toggleNav}>
              Services
            </a>
            <a href="#customers" className={styles.navItem} onClick={toggleNav}>
              Who needs us
            </a>
            <a href="#benefits" className={styles.navItem} onClick={toggleNav}>
              Why choose us
            </a>
            <a href="#contact" className={styles.navItem} onClick={toggleNav}>
              Contact
            </a>
          </nav>
        </div>
        <div className={styles.hamburger} onClick={toggleNav}>
          <i className="fas fa-bars"></i>
        </div>
      </div>
    </header>
  );
};

export default Header;
