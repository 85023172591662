import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCreateQueryMutation, useSendOtpMutation, useVerifyOtpMutation, useCheckEmailVerificationStatusQuery, OtpActions } from '../../gql/graphql';
import styles from "./Booking.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import Cookies from 'js-cookie';
import encryptWithPublicKey from './../../Utils/encryption';
import useQueryFormData from './../../hooks/useQueryForm';

const Booking: React.FC = () => {
  const [meetingDateTime, setMeetingDateTime] = useState('');
  const initialQueryData = { name: '', email: '', phoneNo: '', description: '', otp: '' };
  const [formData, errors, handleInputChange, clearForm, isFormValid] = useQueryFormData(initialQueryData);
  const [otpSent, setOtpSent] = useState(false);
  const [createBooking, { loading: creating, error }] = useCreateQueryMutation();
  const [sendOtp, { loading: sendingOtp }] = useSendOtpMutation();
  const [verifyOtp, { loading: verifyingOtp }] = useVerifyOtpMutation();
  const { data: verificationData, loading: checkingVerification } = useCheckEmailVerificationStatusQuery({
    variables: { email: formData.email, method: OtpActions.CREATE_QUERY },
    skip: !formData.email
  });

  const handleDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMeetingDateTime(e.target.value);
  };

  const getFormattedDateTime = (p0: Date) => {
    const date = p0.toISOString().substring(0, 10);
    const time = p0.toTimeString().substring(0, 5);
    return `${date}T${time}`;
  };

  const minDateTime = getFormattedDateTime(new Date());

  const handleSubmit = async () => {
    if (!isFormValid()) {
      toast.error('Please fill all fields correctly.');
      return;
    }

    if (!verificationData?.checkEmailVerificationStatus?.success && !otpSent) {
      const sendResult = await sendOtp({ variables: { email: formData.email, method: OtpActions.CREATE_QUERY } });
      if (sendResult.data?.sendOtp?.success) {
        toast.success('OTP sent successfully.');
        setOtpSent(true);
      } else {
        toast.error(sendResult.data?.sendOtp?.message || 'Failed to send OTP. Please try again.');
      }
    } else if (otpSent) {
      const verifyResult = await verifyOtp({ variables: { email: formData.email, method: OtpActions.CREATE_QUERY, inputOtp: formData.otp } });
      if (verifyResult.data?.verifyOtp?.success) {
        setOtpSent(false);
        processBooking();
      } else {
        toast.error(verifyResult.data?.verifyOtp?.message || 'OTP verification failed. Please check the OTP and try again.');
      }
    } else {
      processBooking();
    }
  };

  const processBooking = async () => {
    const publicKey = Cookies.get('_SPK');
    if (!publicKey) {
      toast.error('Public key not found. Cannot encrypt data.');
      return;
    }
    try {
      const encryptedData = {
        name: await encryptWithPublicKey(publicKey, formData.name),
        email: await encryptWithPublicKey(publicKey, formData.email),
        phoneNo: await encryptWithPublicKey(publicKey, formData.phoneNo),
        subject: "Book a Virtual Meet",
        description: await encryptWithPublicKey(publicKey, `${formData.description}. Contact Phone: ${formData.phoneNo}. Meeting Date and Time: ${meetingDateTime}`)
      };

      const response = await createBooking({
        errorPolicy: 'all',
        variables: {
          input: encryptedData
        }
      });

      if (response.data && !error) {
        toast.success('Booking successfully created.');
        clearForm();
      } else {
        toast.error(`Failed to create booking: ${error?.message}`);
      }
    } catch (err) {
      console.error('Error encrypting booking data:', err);
      toast.error('Encryption failed. Please check the console for more details.');
    }
  };

  useEffect(() => {
    setMeetingDateTime(getFormattedDateTime(new Date(Date.now() + 60 * 60 * 1000))); // Default to one hour from now
  }, []);

  return (
    <section id="contact" className={styles.bookingSection}>
      <div className={styles.container}>
        <div className={styles.sectionHeading}>
          <h2>Book meeting now</h2>
          <p>Thanks for your interest in Ducat</p>
        </div>
        <div className={styles.contactContainer}>
          <div className={styles.contactDetails}>
            <p>Lorem ipsum posuere nec ultricies et, ipsum posuere nec ultricies et, ipsum posuere nec ultricies et, ipsum posuere nec ultricies et, ipsum posuere nec ultricies et, finibus vel magna. Vestibulum semper, justo.</p>
            <h3>Contact Info</h3>
            <div className={styles.detail}>
              <FontAwesomeIcon icon={faPhone} className={styles.icon} />
              <p>+91-72920 54196</p>
            </div>
            <div className={styles.detail}>
              <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
              <p>info@ducatgroup.com</p>
            </div>
            <div className={styles.detail}>
              <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
              <p>
                B-40, UG-1, Ramprashtha, Colony Chander Nagar, Chander Nagar,
                Ghaziabad, 201011, U.P. India.
              </p>
            </div>
            <div className={styles.detail}>
              <FontAwesomeIcon icon={faLinkedinIn} className={styles.icon} />
              <p>
                <a href="https://www.linkedin.com/company/ducat-consultancy-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                  Ducat Consultancy Pvt Ltd
                </a>
              </p>
            </div>
          </div>
          <div className={styles.formContainer}>
            <h3>Book a Virtual Meet</h3>
            <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
              <div className={styles.formGroup}>
                <input
                  type="text"
                  name="name"
                  id="enquiryName"
                  placeholder="Enter Name here"
                  // autoFocus
                  value={formData.name}
                  onChange={handleInputChange}
                  className={styles.field}
                  required
                />
                {errors.name && <p className={styles.error}>{errors.name}</p>}
                <label htmlFor="enquiryName" className={styles.label}>Enter Name here</label>
              </div>
              <div className={styles.formGroup}>
                <input
                  type="email"
                  name="email"
                  id="enquiryEmail"
                  placeholder="Email address"
                  className={styles.field}
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                {errors.email && <p className={styles.error}>{errors.email}</p>}
                <label htmlFor="enquiryEmail" className={styles.label}>Email address</label>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.phoneContainer}>
                  <div className={styles.country}>
                    <div className={styles.countryFlag}>🇮🇳</div>
                    <div className={styles.countryCode}>+91</div>
                  </div>
                  <input
                    type="tel"
                    id="enquiryMobile"
                    name="phoneNo"
                    placeholder="Mobile"
                    className={`${styles.field} ${styles.enquireField}`}
                    value={formData.phoneNo}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {errors.phoneNo && <p className={styles.error}>{errors.phoneNo}</p>}
                <label htmlFor="enquiryMobile" className={styles.label}>Mobile</label>
              </div>
              <div className={styles.formGroup}>
                <input
                  type="datetime-local"
                  id="meetingDateTime"
                  name="meetingDateTime"
                  className={styles.field}
                  value={meetingDateTime}
                  onChange={handleDateTimeChange}
                  min={minDateTime}
                  required
                />
                {errors.meetingDateTime && <p className={styles.error}>{errors.meetingDateTime}</p>}
                <label htmlFor="meetingDateTime" className={styles.label}>
                  Choose Meeting Date and Time
                </label>
              </div>
              <div className={styles.formGroup}>
                <textarea
                  name="description"
                  id="enquiryRemarks"
                  placeholder="Write some description here..."
                  className={`${styles.field} ${styles.textarea}`}
                  value={formData.description}
                  onChange={handleInputChange}
                  required
                />
                {errors.description && <p className={styles.error}>{errors.description}</p>}
                <label htmlFor="enquiryRemarks" className={styles.label}>Write some description here...</label>
              </div>
              <p className={styles.notice}>** Your data is safe with us and will only be used to schedule and confirm your meeting.</p>
              {otpSent && (
                <div className={styles.formGroup}>
                  <input type="text" name="otp" className={styles.field} placeholder="Enter OTP" value={formData.otp} onChange={handleInputChange} required />
                  {errors.otp && <p className={styles.error}>{errors.otp}</p>}
                </div>
              )}
              <button onClick={handleSubmit} disabled={creating || sendingOtp || verifyingOtp} className={styles.submitButton}>
                {creating || sendingOtp || verifyingOtp ? (
                  <div className={styles.loader}></div> // Display loader when processing
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Booking;
