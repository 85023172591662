import React from "react";
import styles from "./Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.detail}>
          <p>&copy; 2024 Ducat | Designed by Bloomcode Technologies</p>
        </div>

        <div className={styles.detail}>
          <a href="https://www.linkedin.com/company/ducat-consultancy-pvt-ltd/">
            <FontAwesomeIcon icon={faLinkedinIn} className={styles.icon} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
