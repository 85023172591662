import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import About from "./Components/About/About";
import Services from "./Components/Services/Services";
import Customers from "./Components/Customers/Customers";
import Benefits from "./Components/Benefits/Benefits";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Hero from "./Components/Hero/Hero";
import Contact from "./Components/Contact/Contact";
import Enquiry from "./Components/Enquiry/Enquiry";
import Booking from "./Components/Meeting/Booking";
import TopHeader from "./Components/TopHeader/TopHeader";
import Review from "./Components/Review/Review";
import Team from "./Components/Team/Team";
import verifyClient from './Utils/verifyClient';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [sessionIsValid, setSessionIsValid] = useState(Boolean);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const isValid = await verifyClient();
        console.log(isValid);
        setSessionIsValid(isValid);
      } catch (error) {
        console.error("Error verifying client:", error);
        setSessionIsValid(false); // Assume session is invalid on error
      } finally {
        setLoading(false);
      }
    };
      checkSession();

     
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      {sessionIsValid ? (
        <Router>
          <TopHeader />
          <Header />
          <Enquiry />
          <Hero />
          <About />
          <Services />
          <Customers />
          <Benefits />
          <Booking />
          {/* <Contact /> Uncomment or remove based on actual usage */}
          <Review />
          <Team />
          <Footer />
        </Router>
      ) : (
        <p>Client does not exist</p> // Adjust according to your route settings
      )}
    </>
  );
}

export default App;
