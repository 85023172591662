import { ApolloClient, InMemoryCache } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const client = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache()
});

export default client;
