import React from "react";
import styles from "./Hero.module.scss";

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.overlay}>
        <div className={styles.content}>
          <h1>Come be a part of Sustainable World</h1>
          <p>We design, document, implement, train your staff and operate simple system to fulfil professional certification norms.It will be our endeavour to create a robust system. A system that will operate within your existing business format and also help you identify areas of concern and address them without affecting the growth of your business.</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
