import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./Review.module.scss";
import { Swiper as SwiperClass } from 'swiper/types'; // Importing Swiper class type for better TypeScript support

// Define a type for your data objects
type TestData = {
  quote: string;
  author: string;// Mark as optional if not every object will have this property
};

const data = [
  {
    quote:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lorem urna, consequat at felis ac, u lectus dolor, posuere nec ultricies et, finibus vel magna. Vestibulum semper, justo",
    author: "Rohit Sain"
  },
  {
    quote:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lorem urna, consequat at felis ac, u lectus dolor, posuere nec ultricies et, finibus vel magna. Vestibulum semper, justo",
    author: "Vikas",
  },
  {
    quote:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lcumsan nisi suscipit. Nunc lectus dolor, posuere nec ultricies et, finibus vel magna. Vestibulum semper, justo",
    author: "Tanisha",
  },
  {
    quote:
      "Lorem ipsum dolorrem urna, consequat at felis ac, ultrices tempor tellus. Mauris mattis odio dignissim dolor iaculis, in accumsan nisi suscipit. Nunc lectus dolor, posuere nec ultricies et, finibus vel magna. Vestibulum semper, justo",
    author: "Smriti",
  },
  {
    quote:
      "Lorem ipsum dolor am lorem urna, consequat at felis ac, ultrices tempor tellus. Mauris mattis odio dignissim dolor iaculis, in accumsan nisi suscipit. Nunc lectus dolor, posuere nec ultricies et, finibus vel magna. Vestibulum semper, justo",
    author: "Lakshay",
  },
];
const Review = () => {
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  return (
    <section className={styles.reviewSection}>
      <div className={styles.container}>
        <div className={styles.sectionHeading}>
          <h2>Our Reviews</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
        </div>
        <div className={styles.swiperButtons}>
          <div ref={navigationPrevRef} className={styles.swiperButtonPrev}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div ref={navigationNextRef} className={styles.swiperButtonNext}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
        <Swiper
          modules={[Pagination, Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={3}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper: SwiperClass) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          loop={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
        >
          {data.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className={styles.slide}>
                <div className={styles.testimonialContent}>
                  <p className={styles.quote}>{testimonial.quote}</p>
                  <div className={styles.testimonialFooter}>
                    <div>
                      <p className={styles.author}>{testimonial.author}</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Review;
